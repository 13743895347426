import axios from 'axios'
/**
 * @type {import('./box').BoxApi}
 */
const DeviceErrApi = {
  getdevicepre (path, query) {
    return axios.get('/adwall/api/miss/device/pre', {
      params: Object.assign({
        path
      }, query)
    })
  }
}

export default DeviceErrApi
